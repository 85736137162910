import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import ButtonAppBar from './appBar';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Gallery from "./pages/Gallery"
import Home from './pages/Home';
import { Toaster } from 'react-hot-toast';

function App() {
  const { isAuthenticated } = useAuth0();

  return (
    <BrowserRouter>
      {isAuthenticated && <ButtonAppBar />}
      <Toaster />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;