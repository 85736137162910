import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";

// domain = "dev-nfufcfr4.au.auth0.com"
// clientId = "x4CPy0Wy8i4AJlwfTiIxmU3PC2xyTmjH"

ReactDOM.render(
  <Auth0Provider
    domain="dev-nfufcfr4.au.auth0.com"
    clientId="x4CPy0Wy8i4AJlwfTiIxmU3PC2xyTmjH"
    redirectUri={window.location.origin}
    useRefreshTokens
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
)
