import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Button,
  CircularProgress,
  Container,
  Skeleton,
  ImageListItem,
} from "@mui/material";
import axios from "axios";
import aws4 from "aws4";
import X2JS from "x2js";
import toast from "react-hot-toast";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

const Gallery = () => {
  const [allImageData, setAllImageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imgUpload, setImgUpload] = useState(false);
  const [galleryView, setGalleryView] = useState(false);

  const uploadFile = async (result, name) => {
    const data = result.split("base64,")[1];
    const bufferData = new Buffer.from(data, "base64");

    const opts = {
      host: "pia-and-coj.s3.ap-southeast-2.amazonaws.com",
      service: "s3",
      region: "ap-southeast-2",
      method: "PUT",
      path: "/" + name,
      body: bufferData,
      headers: {
        "Content-Type": "image/jpeg",
      },
    };
    aws4.sign(opts, {
      accessKeyId: "AKIAUUY25URQHRJ2QAP2",
      secretAccessKey: "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",
    });

    const xContent = opts.headers["X-Amz-Content-Sha256"];
    const xDate = opts.headers["X-Amz-Date"];
    const xAuth = opts.headers.Authorization;

    var config = {
      method: "PUT",
      url: "https://pia-and-coj.s3.ap-southeast-2.amazonaws.com/" + name,
      headers: {
        "X-Amz-Content-Sha256": xContent,
        "X-Amz-Date": xDate,
        Authorization: xAuth,
        "Content-Type": "image/jpeg",
      },
      data: opts.body,
    };
    setImgUpload(true);
    axios(config)
      .then(function (response) {
        setAllImageData((prev) => [{ img: result, title: name }, ...prev]);
        setImgUpload(false);
      })
      .catch(function (error) {
        setImgUpload(false);
        toast.error(error?.response || "Something went wrong");
      });
  };

  const onChnageFileAndUpload = async (e) => {
    const fileToUpload = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function (e) {
      const result = e.target.result;
      uploadFile(result, fileToUpload.name);
    };
    reader.readAsDataURL(fileToUpload);
  };

  useEffect(() => {
    const x2js = new X2JS();

    const opts = {
      host: "pia-and-coj.s3.ap-southeast-2.amazonaws.com",
      path: "/",
      service: "s3",
      region: "ap-southeast-2",
    };
    aws4.sign(opts, {
      accessKeyId: "AKIAUUY25URQMMVZW3ZE",
      secretAccessKey: "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",
    });
    const xContent = opts.headers["X-Amz-Content-Sha256"];
    const xDate = opts.headers["X-Amz-Date"];
    const xAuth = opts.headers.Authorization;
    var config = {
      method: "get",
      url: "https://pia-and-coj.s3.ap-southeast-2.amazonaws.com/",
      headers: {
        "X-Amz-Content-Sha256": xContent,
        "X-Amz-Date": xDate,
        Authorization: xAuth,
      },
      //  responseType: "blob",
    };
    axios(config)
      .then((res) => {
        setLoading(true);
        const jsonData = x2js.xml2js(res.data).ListBucketResult.Contents || [];
        if (jsonData.length > 0) {
          jsonData.forEach((file, inx) => {
            const opts = {
              host: "pia-and-coj.s3.ap-southeast-2.amazonaws.com",
              path: "/" + file.Key,
              service: "s3",
              region: "ap-southeast-2",
            };
            aws4.sign(opts, {
              accessKeyId: "AKIAUUY25URQMMVZW3ZE",
              secretAccessKey: "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",
            });

            const xContent = opts.headers["X-Amz-Content-Sha256"];
            const xDate = opts.headers["X-Amz-Date"];
            const xAuth = opts.headers.Authorization;
            var config = {
              method: "get",
              url:
                "https://pia-and-coj.s3.ap-southeast-2.amazonaws.com/" +
                file.Key,
              headers: {
                "X-Amz-Content-Sha256": xContent,
                "X-Amz-Date": xDate,
                Authorization: xAuth,
              },
              responseType: "blob",
            };

            axios(config)
              .then((res) => {
                const contentUrl = URL.createObjectURL(res.data);
                const newImage = new Image();
                newImage.src = contentUrl;
                newImage.onload = function () {
                  setAllImageData((prev) => [
                    {
                      img: contentUrl,
                      title: file.Key,
                      height: newImage.height,
                      width: newImage.width,
                    },
                    ...prev,
                  ]);
                };

                setLoading(false);
              })
              .catch((err) => {
                toast.error(err?.response || "Something went wrong");
                setLoading(false);
              });
          });
        }
      })
      .catch((e) => toast.error(e?.response || "Something went wrong"));

    setGalleryView(true);
  }, []);

  useEffect(() => {
    if (galleryView && !loading) {
      let lightbox = new PhotoSwipeLightbox({
        gallery: "#test-app",
        children: "a",
        pswpModule: () => import("photoswipe"),
      });
      lightbox.init();

      return () => {
        lightbox.destroy();
        lightbox = null;
      };
    }
  }, [galleryView, loading]);

  return (
    <Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
        }}
      >
        <Button disabled={imgUpload} variant="contained" component="label">
          {imgUpload ? <CircularProgress size={20} /> : "Upload Image"}
          <input
            onChange={onChnageFileAndUpload}
            hidden
            accept="image/*"
            multiple
            type="file"
          />
        </Button>
      </div>

      <div>
        <h2>Gallery :</h2>

        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {[1, 2, 4, 5, 6, 7, 8]?.map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={300}
                height={300}
              />
            ))}
          </div>
        ) : (
          <div
            className="pswp-gallery"
            id={"test-app"}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {allImageData.map((image, index) => (
              <a
                href={image.img}
                data-pswp-width={image.width}
                data-pswp-height={image.height}
                key={index}
                target="_blank"
                rel="noreferrer"
                className="pswp-gallery__item1"
              >
                <ImageListItem
                  style={{
                    width: "275px",
                    height: "275px",
                  }}
                  key={image.img}
                >
                  <img
                    className="my-img"
                    src={image.img}
                    alt={image.title}
                    loading="lazy"
                    style={{ objectFit: "contain" }}
                  />
                </ImageListItem>
              </a>
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(Gallery);
