import "../App.css"
import LoginButton from "../loginButton"
import { useAuth0 } from "@auth0/auth0-react"
import Counter from "../counter"

function Home() {
  const { isAuthenticated } = useAuth0()
  console.log(isAuthenticated)

  return (
    <div className="App">
      {!isAuthenticated ? (
        <div>
          <p style={{ fontSize: "1.5rem" }}>Please Login.</p>
          <LoginButton />
        </div>
      ) : (
        <div>
          <Counter />
        </div>
      )}
    </div>
  )
}

export default Home