import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutButton from './logoutButton';
import { Drawer } from '@mui/material';
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import {PhotoAlbum, HomeOutlined} from '@mui/icons-material'
import { Link } from 'react-router-dom';

export default function ButtonAppBar() {
  const [showMenu, setShowMenu] = React.useState(false)

    const toggleDrawer = () => {
     setShowMenu(!showMenu)
    }

  const list = () => (
    <Box  role="presentation">
      <List>
        <Link
          to={"/"}
          style={{
            textDecoration: "none",
            color: "#000",
          }}
          onClick={toggleDrawer}
        >
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <HomeOutlined />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link
          to={"/gallery"}
          style={{
            textDecoration: "none",
            color: "#000",
          }}
          onClick={toggleDrawer}
        >
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <PhotoAlbum />
              </ListItemIcon>
              <ListItemText primary={"Gallery"} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  )

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer anchor="left" open={showMenu} style={{
      }} onClose={toggleDrawer}>
        {list("top")}
      </Drawer>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setShowMenu(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Pia & Coj
          </Typography>
          <LogoutButton />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

