import { useState, useEffect } from "react";
import "./App.css";
import spaghetti from "./cake-shop.jpg";

function Counter() {
  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-02-23`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  // const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  return (
    <div className="App-counter">
      <img src={spaghetti} className="App-logo" alt="logo" />
      <h1>Pia & Coj Countdown</h1>
      {/* <h2></h2> */}
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </div>
  );
}

export default Counter;

// /  headers: {
//         "X-Amz-Content-Sha256":
//           "e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855",
//         "X-Amz-Date": `${new Date()
//           .toISOString()
//           .split(":")
//           .join("")
//           .split("-")
//           .join("")
//           .split(".")
//           .join()
//           .split(",")
//           .join("")}`,
//         Authorization: `AWS4-HMAC-SHA256 Credential=AKIAUUY25URQMMVZW3ZE/${fullYear}${thisMonth}${thisDay}/ap-southeast-2/s3/aws4_request, SignedHeaders=host;x-amz-content-sha256;x-amz-date`,
//       },
